<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title class="headline text-uppercase">
        <img
          src="logo.png"
          class="my-3"
          height="50"
        >
      </v-toolbar-title>
      
    </v-app-bar>
    
    <v-content>
      
      <HomeRicerca/>
      <iframe src="https://services.conversation-inc.com/webchat/app/7GBG0TnN6znQlRArRIxlsvhBeom8I6gdjcVbCxzCotgTD62pdcmnWm8Jf7k7QHYM?v=v0.1&hide_header=1" style="display:block; border:0; width:95vw; min-width:360px; max-width:420px; margin:30px auto 0; height:600px"></iframe>
     


    </v-content>
  </v-app>
</template>

<script>
//import HomeLogo from './components/HomeLogo';
import HomeRicerca from './components/HomeRicerca';

export default {
  name: 'App',
  components: {
   // HomeLogo,
    HomeRicerca,
  },
  data: () => ({
    //
    offerte:false
  }),
  created () {
      this.$vuetify.theme.dark = true
    },
  /*mounted () {

    
    this.axios.get('https://api.euroimportpneumatici.com/products/', {
        withCredentials: true,
        headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
        }
    },{
    auth: {
        username: 'api',
        password: 'a7nd(kdi£'
    }
    })
    .then(response => (this.offerte = response.data));
    
    
  },*/
};
</script>
