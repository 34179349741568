<template>
  <div id="search">
    <div class="swrapper container" >
      <p style="margin:5px 15px -10px; font-size:14px">
        <b>Cerca le migliori gomme ai prezzi più bassi</b>
      </p>
      <div id="tabAutoMoto" class="clearfix">
        <a href="#" class="lnkAuto" v-bind:class="{ active: tab == 'a' }" v-on:click="tab ='a'">Auto</a>
        <a href="#" class="lnkMoto" v-bind:class="{ active: tab == 'm' }" v-on:click="tab ='m'">Moto</a>
      </div>
      <template v-if="tab=='a'">
        <div class="clearfix">
         <v-form action="https://www.euroimportpneumatici.com/search.html" method="post" target="_blank" id="nativeForm" style="margin: 0 15px; padding:15px; background:#000; border-radius:5px">
            <input type="hidden" name="theme" value="car">
            <label class="leuro">Larghezza</label>
            <v-select v-model='size1Car'
            :items="optionsCar.size1"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size1" v-model="size1Car">
            
            <label class="leuro">Altezza</label>
            <v-select v-model='size2Car'
            :items="optionsCar.size2"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size2" v-model="size2Car">

            <label class="leuro">Diametro</label>
            <v-select v-model='size3Car'
            :items="optionsCar.size3"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size3" v-model="size3Car">

            <label class="leuro">Velocità</label>
            <v-select v-model='speedCar'
            :items="optionsCar.speed"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="speed" v-model="speedCar">

            <label class="leuro">Marca</label>
            <v-select v-model='brandCar'
            :items="optionsCar.brand"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="brand" v-model="brandCar">

           <v-checkbox color="red darken-3" name="runflat" label="Runflat" dense value="on"></v-checkbox>

           <v-checkbox color="red darken-3" name="renforced" label="Rinforzato" dense value="on"></v-checkbox>

           <v-checkbox color="red darken-3" name="cload" label="Carico C" dense value="on"></v-checkbox>

            <div style="text-align:right">
            <v-btn @click="submit" color="#ab0404">Cerca</v-btn>
          </div>
          </v-form>
        </div>
      </template>
      <template v-if="tab=='m'">
        <div class="clearfix">
          <v-form action="https://www.euroimportpneumatici.com/search.html" method="post" target="_blank" id="nativeForm2" style="margin: 0 15px; padding:15px; background:#000; border-radius:5px">
            <input type="hidden" name="theme" value="motorcycle">
            <label class="leuro">Larghezza</label>
            <v-select v-model='size1Moto'
            :items="optionsMoto.size1"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size1" v-model="size1Moto">
            
            <label class="leuro">Altezza</label>
            <v-select v-model='size2Moto'
            :items="optionsMoto.size2"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size2" v-model="size2Moto">

            <!-- <label class="leuro">Struttura</label>
            <v-select v-model='size3Moto'
            :items="optionsMoto.size3"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select> -->
            <input type="hidden" name="size3" v-model="size3Moto">

            <label class="leuro">Diametro</label>
            <v-select v-model='size4Moto'
            :items="optionsMoto.size4"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="size4" v-model="size4Moto">

            <!-- <label class="leuro">Velocità</label>
            <v-select v-model='speedMoto'
            :items="optionsMoto.speed"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select> -->
            <input type="hidden" name="speed" v-model="speedMoto">

            <label class="leuro">Marca</label>
            <v-select v-model='brandMoto'
            :items="optionsMoto.brand"
            outlined
            dense
            background-color="#fff" 
            color="#000"
            ></v-select>
            <input type="hidden" name="brand" v-model="brandMoto">

           

            <div style="text-align:right">
            <v-btn @click="submit2" color="#357ba1">Cerca</v-btn>
            </div>
          </v-form>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import jsonC from '../assets/carOpt.json';
  import jsonM from '../assets/motoOpt.json';
  export default {
    data () {
      return {
        tab: 'a',
        size1Car: "225",
        size2Car: "45",
        size3Car: "17",
        speedCar: null,
        brandCar:null,
        runflat:null,

        size1Moto: "190",
        size2Moto: "55",
        size3Moto: null,
        size4Moto: "17",
        speedMoto: null,
        brandMoto:null,
        optionsCar: jsonC,//false
        optionsMoto: jsonM//false
      }
    },
    methods: {
      cambiaTipo: function(ns){
         this.tab = ns;

      },
      submit() {
        document.getElementById('nativeForm').submit();
      },
       submit2() {
        document.getElementById('nativeForm2').submit();
      }
    },
  mounted () {

    /*
    this.axios.get('https://api.euroimportpneumatici.com/options/', {
        withCredentials: true,
        headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
        }
    },{
    auth: {
        username: 'api',
        password: 'a7nd(kdi£'
    }
    })
    .then(response => (this.optionsCar = response.data));
    */
    
  },
  }
</script>